import classes from "./ComputerScreen.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";

function ComputerScreen() {
  const [onLogin, setOnLogin] = useState(false);

  const onLoginHandler = () => {
    setTimeout(() => {
      setOnLogin(!onLogin);
    }, 500);
  };

  return (
    <>
      {onLogin && (
        <div className={classes.loading}>
          <div className={classes["lds-spinner"]}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {!onLogin && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeIn", duration: 1 }}
          className={classes.background}
        >
          <div className={classes.title}>
            <h1>Software Developer</h1>
            <h2>by: William Kim</h2>
          </div>
          <Link to="/info" onClick={onLoginHandler}>
            Start Now &gt;
          </Link>
        </motion.div>
      )}
    </>
  );
}

export default ComputerScreen;
