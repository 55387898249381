import classes from "./Contact.module.css";
import exit from "../../../images/exit2.png";
import resume from "../../../images/resumeIcon.png";
import gitHub from "../../../images/githubIcon.png";
import linkedIn from "../../../images/linkedInIcon.png";
import resumepdf from "../../../assets/Resume.pdf";

function Contact({ onContactShow }) {
  const linkedInUrl = "https://www.linkedin.com/in/wkim17/";
  const gitHubUrl = "https://github.com/wkim1704";
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div className={classes.container}>
      <h1>GET IN TOUCH TODAY!</h1>
      <span className={classes.exitBtn} onClick={onContactShow}>
        <img src={exit} alt="exit icon" />
      </span>
      <div className={classes.content}>
        <div>
          <img
            src={linkedIn}
            alt="LinkedIn icon"
            onClick={openInNewTab.bind(null, linkedInUrl)}
          />
          <h1 onClick={openInNewTab.bind(null, linkedInUrl)}>LinkedIn</h1>
        </div>
        <div>
          <img
            src={resume}
            alt="resume icon"
            onClick={openInNewTab.bind(null, resumepdf)}
          />
          <h1 onClick={openInNewTab.bind(null, resumepdf)}>Resume</h1>
        </div>
        <div>
          <img
            src={gitHub}
            alt="GitHub icon"
            onClick={openInNewTab.bind(null, gitHubUrl)}
          />
          <h1 onClick={openInNewTab.bind(null, gitHubUrl)}>GitHub</h1>
        </div>
      </div>
    </div>
  );
}

export default Contact;
