import ComputerScreen from "../components/LoginPage/ComputerScreen";
import ComputerUtil from "../components/backgroundUtil/ComputerUtil";

function LoginPage() {
  return (
    <ComputerUtil>
      <ComputerScreen />
    </ComputerUtil>
  );
}

export default LoginPage;
