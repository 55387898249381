import { Routes, Route, useLocation } from "react-router-dom";
import InfoPage from "../../pages/Info";
import LoginPage from "../../pages/Login";
import { AnimatePresence } from "framer-motion";

function AnimateRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence initial={false} mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<LoginPage />} />
        <Route path="/info" element={<InfoPage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimateRoutes;
