import classes from "./Experience.module.css";
import exit from "../../../images/exit2.png";

function Experience({ onExperienceShow }) {
  return (
    <div className={classes.container}>
      <h1>My Experience</h1>
      <span className={classes.exitBtn} onClick={onExperienceShow}>
        <img src={exit} alt="exit icon" />
      </span>
      <section className={classes.information}>
        <h3>Unitech Engineering |</h3>
        <h4> Front End Engineer</h4>
        <ul>
          <li>
            Built a data management single page application with React for its
            top-down information state management, flexibility of JSX, and tree
            reconciliation based on the virtual DOM to expedite the UI
            development of an SPA
          </li>
          <br />
          <li>
            Architected a flux based UI ecosystem that employed redux for global
            state management to ensure predictable and unidirectional data flow
            and migrated away from prop drilled React components. This
            optimization resulted in a 30% increase in component render cycles
          </li>
          <br />
          <li>
            Leveraged Typescript to apply strict typing of props and state in
            order to minimize unexpected bugs and to improve code quality by
            ensuring self-documenting code
          </li>
          <br />
          <li>
            Generated automated unit and functional tests using Jest and React
            Testing Library for developers to minimize unexpected application
            bugs by evaluating critical edge cases and preserving code quality
            for future updates
          </li>
        </ul>
        <h3>Jayu Real Estate |</h3>
        <h4> Software Engineer</h4>
        <ul>
          <li>
            Developed and maintained the service landing page and online
            business services for Jayu Real Estate and its affiliate businesses{" "}
          </li>
          <br />
          <li>
            Incorporated React Router with React to navigate between UI pages
            without page refreshes in order to minimize requests and maintain
            application state for a single page application
          </li>
          <br />
          <li>
            Engineered an administration dashboard, utilizing a relational
            database (PostgreSQL) to CRUD property listings and prices and to
            show data visualizations using Recharts
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Experience;
