import { BrowserRouter as Router } from "react-router-dom";
import AnimatedRoutes from "./components/AnimatedRoutes/AnimatedRoutes";

function App() {
  return (
    <div>
      <Router basename={process.env.PUBLIC_URL}>
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;

// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import InfoPage from "./pages/Info";
// import LoginPage from "./pages/Login";

// function App() {
//   const router = createBrowserRouter([
//     { path: "/", element: <LoginPage /> },
//     {
//       path: "/info",
//       element: <InfoPage />,
//     },
//   ]);

//   return <RouterProvider router={router} />;
// }

// export default App;
