import classes from "./ComputerUtil2.module.css";
import { motion } from "framer-motion";
import { useMobile } from "../util/mediaQueryUtil";

function ComputerUtil2({ children }) {
  const isMobile = useMobile();
  let zoomVariants = {
    initial: { right: 0, left: 0, top: 0, bottom: 0 },
    animate: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
      transition: {
        duration: 1.5,
      },
    },
    exit: {
      right: "15%",
      left: "15%",
      top: "5.5%",
      bottom: "14.5%",
      transition: {
        duration: 1.5,
      },
    },
  };
  if (!isMobile) {
    zoomVariants = {};
  }
  return (
    <>
      <motion.div
        initial={{
          right: "-24%",
          left: "-24%",
          top: "-8.5%",
          bottom: "-24%",
        }}
        animate={{
          right: "-24%",
          left: "-24%",
          top: "-8.5%",
          bottom: "-24%",
        }}
        transition={{ duration: 1.5 }}
        exit={{
          right: "0%",
          left: "0%",
          top: "0%",
          bottom: "0%",
        }}
        className={classes.background}
      >
        <div className={classes.computerStand}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </motion.div>
      <motion.div
        // initial={{ right: "0%", left: "0%", top: "0%", bottom: "0%" }}
        // animate={{ right: "0%", left: "0%", top: "0%", bottom: "0%" }}
        // transition={{ duration: 1.5 }}
        // exit={{ right: "15%", left: "15%", top: "5.5%", bottom: "14.5%" }}
        variants={zoomVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className={classes.container}
      >
        <div className={classes.monitor}>
          <div className={classes.frame}>
            <div className={classes.arrowUp}></div>
            <div className={classes.arrowDown}></div>
            <div className={classes.arrowRight}></div>
            <div className={classes.arrowLeft}></div>
            <div className={classes.screen}>{children}</div>
          </div>
          <div className={classes.light}>
            <div></div>
          </div>
          <div className={classes.onoff}>
            <div className={classes.button}></div>
          </div>
          <div className={classes.otherButton1}>
            <div></div>
          </div>
          <div className={classes.otherButton2}>
            <div></div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default ComputerUtil2;
