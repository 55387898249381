import InfoScreen from "../components/InfoPage/InfoScreen";
import ComputerUtil2 from "../components/backgroundUtil/ComputerUtil2";
// import Practice from "../components/backgroundUtil/Practice";

function InfoPage() {
  return (
    <ComputerUtil2>
      <InfoScreen />
    </ComputerUtil2>
  );
}

export default InfoPage;
