import classes from "./AboutMe.module.css";
import exit from "../../../images/exit2.png";
// import pfp from "../../../images/pfp.png";

function AboutMe({ onAboutShow }) {
  return (
    <div className={classes.container}>
      <h1>About Me</h1>
      <span className={classes.exitBtn} onClick={onAboutShow}>
        <img src={exit} alt="exit icon" />
      </span>
      <p>
        Hi, my name is William, and I'm a software engineer who strives to build
        highly functional and scalable applications. I enjoy taking an idea and
        turning it into a beautiful, performant software built via clean,
        reusable, and flawlessly designed code. I am proficient in React.js and
        Typescript when developing, but I always strive to learn new
        technologies and evolve my existing skillsets. <br /> <br /> Currently,
        I'm working as a Front End Engineer for Unitech Engineering, where I
        concentrate my time on building the UI for a data analyzing app.
      </p>
      {/* <div className={classes.profilePhoto}>
        <img src={pfp} alt="me" />
      </div> */}
    </div>
  );
}

export default AboutMe;
