import { useState } from "react";
import classes from "./InfoScreen.module.css";
import profImg from "../../images/2.png";
import folderImg from "../../images/1.png";
import AboutMe from "./About/AboutMe";
import Experience from "./Experience/Experience";
import Contact from "./Contact/Contact.jsx";
import { motion } from "framer-motion";
import exitIcon from "../../images/exit2.png";
import { Link } from "react-router-dom";
import pfp from "../../images/pfp.png";

function InfoScreen() {
  const [showAbout, setShowAbout] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const showAboutHandler = () => {
    setShowAbout(!showAbout);
  };
  const showExperienceHandler = () => {
    setShowExperience(!showExperience);
  };
  const showContactHandler = () => {
    setShowContact(!showContact);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeIn", duration: 1 }}
      exit={{ opacity: 0 }}
      className={classes.container}
    >
      {showAbout || showExperience || showContact || (
        <div className={classes.infoContainer}>
          <div className={classes.exitBtn}>
            <Link to="/">
              <img src={exitIcon} alt="exit icon" />
            </Link>
          </div>
          <div className={classes.info}>
            <div className={classes.profile}>
              <img src={profImg} alt="profile" onClick={showAboutHandler} />
              <h1 onClick={showAboutHandler}>About Me</h1>
            </div>
            <div className={classes.experience}>
              <img
                src={folderImg}
                alt="experience"
                onClick={showExperienceHandler}
              />
              <h1 onClick={showExperienceHandler}>Experience</h1>
            </div>
            <div className={classes.contact}>
              <img src={folderImg} alt="contact" onClick={showContactHandler} />
              <h1 onClick={showContactHandler}>Contact</h1>
            </div>
          </div>
        </div>
      )}
      {showAbout && <AboutMe onAboutShow={showAboutHandler} />}
      {showAbout && (
        <div className={classes.profilePhoto}>
          <img src={pfp} alt="me" />
        </div>
      )}
      {showExperience && (
        <Experience onExperienceShow={showExperienceHandler} />
      )}
      {showContact && <Contact onContactShow={showContactHandler} />}
    </motion.div>
  );
}

export default InfoScreen;
